import {
    Card as MaterialCard,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
    Tooltip,
} from "@material-tailwind/react";
   
export function Card(props) {
    const { cardStyles, title, tooltips, button } = props;
    return (
        <MaterialCard className={`shadow-lg flex ${cardStyles}`}>
            <CardHeader className='flex rounded-b-none bg-gray-900 justify-center p-3'>
                <Typography variant="h2" className="font-bold text-gray-300 text-xl">
                    {title}
                </Typography>
            </CardHeader>
            <CardBody className='flex justify-between p-4 w-40 max-w-[20rem] self-center pb-0'>
                {Object.values(tooltips).map(val => (
                    <Tooltip key={`${val.content}`} className="bg-black p-2 rounded-xl text-gray-100" content={`${val.content}`}>
                        <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
                            {val.svg}  
                        </span>
                    </Tooltip>
                ))}
            </CardBody>
            <CardFooter className="p-4">
                <Button 
                    className='p-3 bg-gray-900 text-gray-200 font-bold rounded-xl hover:scale-105' 
                    onClick={() => props.onClickUsesDetailsHandler(button.link, button.tracking)} 
                    fullWidth={true} size="lg"
                >
                    {button.text}
                </Button>
            </CardFooter>
        </MaterialCard>
    );
}