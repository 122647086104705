import ProfileCard from '../components/Profile/ProfileCard';
import ContainerTitle from '../components/UI/ContainerTitle';
import Job from '../components/Experience/Job';
import { positionsInformation, profileInformation } from '../helpers/data';
import { Card } from '../components/UI/Card';
import { usesData } from '../helpers/data';
import { GASendEvent, PageView } from '../metrics/GA';
import { useEffect } from 'react';

function Home() {
  useEffect(() => {
    document.title = 'Dortega Tech';
    PageView();
  }, []);

  const clickUsesDetailsHandler = (link, tracking) => {
    GASendEvent({
      action: 'click',
      params: { category: 'use_details_link', label: tracking },
    });
    window.open(link, '_blank');
  };
  return (
    <>
      <ProfileCard {...profileInformation} />

      <ContainerTitle containerId="Experience">
        {Object.values(positionsInformation).map((val) => {
          return <Job key={val.id} position={val} />;
        })}
      </ContainerTitle>

      <ContainerTitle
        customClasses="flex flex-wrap gap-y-10 justify-evenly mb-28"
        containerId="Uses"
      >
        {Object.values(usesData).map((val) => (
          <Card
            key={val.title}
            {...val}
            onClickUsesDetailsHandler={clickUsesDetailsHandler}
          />
        ))}
      </ContainerTitle>
    </>
  );
}

export default Home;
