import ReactGA from 'react-ga4';

export const initGA = (id) => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize(id);
  }
};

export const PageView = () => {
  ReactGA.send('pageview');
};

export const GASendEvent = ({ action, params: { label, category } }) => {
  ReactGA.event({
    action,
    category,
    label,
  });
};
