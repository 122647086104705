export default function JobInformation (props) {
    const startDateFormatted = props.startDate.toLocaleString('en-US', {
        month: 'long',
        year: 'numeric',
    });

    const endDateFormatted = props.endDate === '' 
        ? 'Present' 
        : props.endDate.toLocaleString('en-US', {
                month: 'long',
                year: 'numeric',
            });

    const endDate = props.endDate === '' 
            ? new Date()
            : props.endDate;

    const totalTime = (startDate, endDate) => {
        const diffTime = Math.abs(endDate - startDate);
        const diffYears = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));
        const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 365 / 12));

        if (diffYears < 1) {
            return diffMonths + (diffMonths > 1 ? ' Months' : ' Month')
        }

        if (Math.floor(diffMonths % 12)  === 0) {
             return diffYears + (diffYears > 1 ? ' Years' : ' Year');
        }

        
        return diffYears + ' Years ' + Math.floor(diffMonths % 12)  + ' Months';
    }

    return (
        <div className='w-full lg:w-1/3 rounded-tl-md rounded-tr-md lg:rounded-bl-md lg:rounded-tr-none bg-gray-900 text-gray-200 p-8 h-fit'>
            <h3 className='font-bold text-xl mb-4'>{props.company}</h3>
            <div className='flex flex-row mb-3 font-bold text-gray-400'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-4 text-rose-600/90" viewBox="0 0 18 18">
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5h16V4H0V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5z"/>
                </svg>
                <small className='ml-1'>{startDateFormatted} - {endDateFormatted} ({totalTime(props.startDate, endDate)})</small>
            </div>
            <div className='flex flex-row mb-3 font-bold text-gray-400'>
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 text-rose-600/90" viewBox="0 0 18 18" fill="currentColor">
                    <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
                </svg>
                <small className='ml-1'>{props.location}</small>
            </div>
            <div className='flex flex-row font-bold text-gray-400'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-4 text-rose-600/90" viewBox="0 0 18 18"> <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/> </svg>
                <small className='ml-1'>
                    <a target='_blank' href={props.websiteLink} rel='noreferrer'>{props.websiteName}</a>
                </small>
            </div>
        </div>
    )
}