import { HashLink as Link } from 'react-router-hash-link';
import { ReactComponent as ShortLogo } from '../../images/short_logo.svg';

export default function Post(props) {
    return (
        <div>
            <div className="bg-gray-900 shadow-2xl w-full md:w-96 rounded-md">
                <div className="mx-auto max-w-7xl">
                    <div className="mx-5 grid max-w-4xl grid-cols-1 gap-x-8 gap-y-16 p-9 lg:grid-cols-1">
                        <article className="flex max-w-xl flex-col items-start justify-between">
                            <div className="w-full flex items-center justify-between text-[12px]">
                                <div className="text-gray-300 font-semibold">
                                    {props.content.dateFormatted}
                                </div>
                                <div className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">
                                    {props.content.category}
                                </div>
                            </div>
                            <div className="group relative">
                                <h3 className="mt-5 text-[18px] font-semibold leading-6 text-rose-600/90 group-hover:text-rose-800">
                                    <Link to={`/blog/${props.content.slug}`}>
                                        <span className="absolute inset-0">
                                        </span>
                                        {props.content.title}
                                    </Link>
                                </h3>
                                <p className="mt-4 line-clamp-3 text-[14px] leading-6 text-gray-300">
                                    {props.content.preview}
                                </p>
                            </div>
                            <div className="relative mt-6 flex items-center gap-x-4">
                               <ShortLogo />


                                <div className="text-sm leading-6">
                                    <p className="font-semibold text-gray-400">
                                        <span className="absolute inset-0"></span>
                                        {props.content.author}
                                    </p>
                                    <p className="text-gray-500">{props.content.position}</p>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    );
}