export default function HoverCard(props) {
    return (
        <div className="rounded-md grid grid-cols-12 dark:bg-gray-900 shadow p-6 gap-2 items-center hover:shadow-lg transition delay-150 duration-300 ease-in-out md:hover:scale-105 transform mb-8 last:mb-0">
            <div className="col-span-11">
                <p className="text-rose-600/90 font-semibold"> {props.title} </p>
            </div>
            <div className={`col-span-11 ${props.customStyles}`}>
                { props.children }
            </div>
        </div>
    )
}