export default function StandingDesk(props) {
    return (
      <div
        style={{
          color: '#E0E0E0',
          fontWeight: '500',
          fontSize: '16px',
          lineHeight: '2rem',
        }}>
            So, why am I writting this post? I've been working from home since 2019 <i> (yes, lucky guy who was already working remotely before the world exploded in 2020 and keep doing it) </i> and it's been a while since I decided to try a Standing Desk.
            <br />
            I must admit that I am an enthusiast of technology and I love walking around Twitter among other sites getting ideas from the setups of other developers and many times (more than my bank account would recommend 😅) they manage to create a need to try new gadgets.
            <br />However sometimes those new gadgets simply do not fit with my necessities and I end up returning them or keeping forgotten in the box room but Standing Desk will not be the case.
            <br /> <br />
            I'm gonna tell you the reasons why I decided to try it and why I'm keeping it but also, I'm gonna tell you a few cons that you should consider before buying one.
            <h2 className="font-bold text-3xl mt-10">PROS</h2>
            <ol className="mt-6">
                <li className="mt-4">
                    <b>Standing after meals: (even eating): </b>
                    This may seem like a stupid reason, but it is honestly one of the ones I value most after so much time. My stomach is happy to be able to digest correctly every day.
                </li>
                <li className="mt-4">
                    <b>Energy Boost, No Coffee Needed: </b>
                    Stand up, get that blood flowing, and suddenly you're wide awake! No need for that third cup of coffee or the supplements before the paddle match. 🏆 (also your stomach will thank you)
                </li>
                <li className="mt-4">
                    <b>Chillin' with Good Posture: </b>
                    No more hunching over like the Hunchback of Notre-Dame. Standing desks make you stand tall, improving your posture game.
                </li>
                <li className="mt-4">
                    <b>Burn Calories While You Work: </b>
                    Who says you can't work on that summer bod while listening to your boss promising impossible deliveries or breaking production with your mates? 
                    <br /> Standing burns more calories than sitting but there's also the possibility to buy a treadmill and walk to the moon while working! - multitasking at its finest!
                </li>
                <li className="mt-4">
                    <b>Be the cool kid in the Team: </b>
                    If you are the only one in your team with a Standing Desk, you will be the coolest kid in the team. I promise.
                </li>
            </ol>
            <h2 className="font-bold text-3xl mt-10">CONS</h2>
            <ol className="mt-6">
                <li className="mt-4">
                    <b>MONEY!: </b>
                    They are not really cheap, I see it as an investment in my health but it is true that it is not a cheap investment. (Your wallet might shed a tear)
                    <br />There are a few options with reasonable prices but you have to be careful with the quality of the materials.
                </li>
                <li className="mt-4">
                    <b>Not a One-Size-Fits-All Solution: </b>
                    Some tasks are just easier to tackle sitting down. Like, who wants to recover a database wrongly deleted in production while standing?
                </li>
                <li className="mt-4">
                    <b>Desk Space Struggles: </b>
                    If your office space is tighter than skinny jeans, a Standing Desk might cramp your style.
                </li>
            </ol>
            <br />
            I hope, with those pros and cons, you can make a little decision tree to see if it's worth it for you. 
            As I said before it's not a solution that can fits everyone but if you're really lazy and do not have the control to stand up every hour or so, this could be a great solution for you.
      </div>
    )
}