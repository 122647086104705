import Footer from '../components/Footer/Footer';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import { initGA } from '../metrics/GA';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Navbar } from '../components/Header/Navbar';
import { profileInformation } from '../helpers/data';

function Layout() {
  const handleAcceptCookie = () => {
    initGA('G-TCWXH15CHZ');
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === 'true') {
      handleAcceptCookie();
    }
  }, []);

  return (
    <>
      <Navbar />
      <Outlet />
      <Footer {...profileInformation}></Footer>
      <CookieConsent
        onAccept={handleAcceptCookie}
        enableDeclineButton
        buttonText="Accept"
        declineButtonText="Decline"
        buttonStyle={{
          color: '#FFFFFF',
          fontSize: '13px',
          backgroundColor: '#8CCB29',
        }}
        declineButtonStyle={{
          color: '#FFFFFF',
          backgroundColor: 'rgb(31 41 55, 0.6)',
          fontSize: '13px',
        }}
        style={{ backgroundColor: '#111827', opacity: 0.9, fontSize: '13px' }}
      >
        This website uses cookies to enhance your experience.
      </CookieConsent>
    </>
  );
}

export default Layout;
