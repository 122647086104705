import HoverCard from '../UI/HoverCard'

export default function JobDetail(props) {
    const positionId = props.positionId;
    const renderExperiences = (experiences) => {
        return Object.values(experiences).map(([key, val], index) => {
            return <p key={`experience-${index}`} className="text-sm font-bold leading-6 text-gray-400">{experiences[index]}</p>
        })
    }
    const renderTechStack = (techStack) => {
        if (techStack === undefined) return (<p className="text-sm font-bold leading-6 text-gray-400">No technologies listed</p>)

        return Object.values(techStack).map(([key, val], index) => (
            <img key={`tech-stack-${index}`} src={ require(`../../images/technologies/${techStack[index]}.png`)} width='35' height='35' alt={techStack[index]} loading="lazy" />
        ))
    }
    return (
        <div key={`detail-container-${positionId}`} className='w-full lg:w-2/3 lg:rounded-tr-md rounded-br-md rounded-bl-md bg-gray-700 p-8 text-gray-300'>
            <HoverCard key={`experience-container-${positionId}`} title="Experience and Responsibilities">
                {renderExperiences(props.experience)}
            </HoverCard>
            {props.techStack && <HoverCard key={`tech-container-${positionId}}`} title="Technologies" customStyles="flex md:justify-evenly gap-4 flex-wrap">
                {renderTechStack(props.techStack)}
            </HoverCard>}
        </div>
    )
}