import { ReactComponent as KeyboardSvg } from '../images/uses/keyboard.svg';
import { ReactComponent as LogitechSvg } from '../images/uses/logitech.svg';
import { ReactComponent as KeychronSvg } from '../images/uses/keychron.svg';
import { ReactComponent as MouseSvg } from '../images/uses/mouse.svg';
import { ReactComponent as AppleSvg } from '../images/uses/apple.svg';
import { ReactComponent as LaptopSvg } from '../images/uses/laptop.svg';
import { ReactComponent as DeskSvg } from '../images/uses/desk.svg';
import { ReactComponent as ChairSvg } from '../images/uses/chair.svg';
import { ReactComponent as IkeaSvg } from '../images/uses/ikea.svg';
import { ReactComponent as FlexiSpotSvg } from '../images/uses/flexispot.svg';
import { ReactComponent as DellSvg } from '../images/uses/dell.svg';
import { ReactComponent as ScreenSvg } from '../images/uses/screen.svg';
import { ReactComponent as TreadmillSvg } from '../images/uses/treadmill.svg';
import { ReactComponent as AmazonSvg } from '../images/uses/amazon.svg';

export const profileInformation = {
  name: 'Daniel Ortega Ortega',
  image: require('../images/daniel-black-white-3.jpg'),
  location: 'Alicante, Spain',
  position: 'Software Engineer',
  company: 'The Knot Worldwide',
  selfDescription:
    'Enthusiastic developer with more than 5 years of dedicated experience specializing in PHP and its prominent Laravel and Symfony frameworks. <br> Eagerly expanding expertise by delving into NodeJS, Typescript, and React technologies, showcasing a commitment to continuous learning and staying at the forefront of cutting-edge development tools.',
  rss: {
    linkedin: 'https://www.linkedin.com/in/daniel-ortega-ortega-390624161/',
    github: 'https://github.com/Daniortegaortega',
    twitter: 'https://twitter.com/_daniortega_',
    instagram: 'https://www.instagram.com/daniortega4/',
  },
};

export const positionsInformation = [
  {
    id: 1,
    positionInformation: {
      company: 'The Knot Worldwide',
      startDate: new Date('2022-11'),
      endDate: '',
      location: 'Barcelona, Spain (Remote)',
      websiteName: 'Theknotww.com',
      websiteLink: 'https://www.theknotww.com/',
    },
    positionDetail: {
      experience: [
        'Demonstrated the ability to work effectively both independently and as part of a collaborative team, addressing and resolving challenges with a proactive and solution-oriented approach.',
        'Acquired and honed skills within an innovative tech stack, including in-depth experience with Typescript, React, and GraphQL. Successfully applied this knowledge to streamline development processes and enhance project outcomes.',
        'Engaged in close collaboration with the Product, Design and QA teams, offering a diverse range of approaches and solutions to ensure the delivery of high-quality software products. Proactively contributed insights that led to the refinement of testing methodologies and overall project success.',
      ],
      techStack: [
        'php',
        'symfony',
        'mysql',
        'javascript',
        'typescript',
        'css3',
        'react',
        'docker',
        'aws',
        'jira',
      ],
    },
  },
  {
    id: 2,
    positionInformation: {
      company: 'Planeta Huerto',
      startDate: new Date('2021-11'),
      endDate: new Date('2022-11'),
      location: 'Alicante, Spain (Remote)',
      websiteName: 'PlanetaHuerto.es',
      websiteLink: 'https://www.planetahuerto.es',
    },
    positionDetail: {
      experience: [
        'Worked productively with Product Team to understand requirements and business specifications around Portfolio Management, Analytics and Risk.',
        'Developed skills on warehouse processes and workflows on Ecommerce.',
        'Built new features applying Solid, Design Patterns, DDD, Hexagonal Architecture and CQRS.',
        'Created detailed technical and user documentation.',
      ],
      // techStack: 'PHP - Symfony - MySQL - Javascript - Docker - Kafka - Google Cloud',
      techStack: [
        'php',
        'symfony',
        'mysql',
        'javascript',
        'css3',
        'webpack',
        'docker',
        'jenkins',
        'google-cloud',
      ],
    },
  },
  {
    id: 3,
    positionInformation: {
      company: 'Square1 Development',
      startDate: new Date('2018-03'),
      endDate: new Date('2021-11'),
      location: 'Dublin, Ireland (Remote)',
      websiteName: 'Square1.io',
      websiteLink: 'https://www.square1.io',
    },
    positionDetail: {
      experience: [
        'I was able to assist in conversations with managers and clients during scope and analysis phases which helped us during development phases generating some benefits in the timelines and revenue.',
        'Lead a small team during some stages of development and during the long-term maintenance period on many projects.',
        'Worked in custom integration of payment providers like Stripe and Paypal in multiple projects which allowed some clients increase their revenue in more than 30%.',
      ],
      // techStack: 'PHP - Laravel - MySQL - Javascript - SASS - AWS',
      techStack: [
        'php',
        'laravel',
        'mysql',
        'javascript',
        'css3',
        'webpack',
        'aws',
        'jira',
      ],
    },
  },
];

export const usesData = [
  {
    cardStyles:
      'w-full bg-white md:w-[calc(50%-2rem)] lg:w-[calc(33%-2rem)] xl:w-[calc(25%-2rem)]',
    title: 'M1 MacBook Pro 16"',
    tooltips: [
      {
        content: 'Laptop',
        svg: <LaptopSvg />,
      },
      {
        content: 'Apple',
        svg: <AppleSvg />,
      },
    ],
    button: {
      text: 'More details',
      link: 'https://www.apple.com/macbook-pro',
      tracking: 'laptop',
    },
  },
  {
    cardStyles:
      'w-full bg-white md:w-[calc(50%-2rem)] lg:w-[calc(33%-2rem)] xl:w-[calc(25%-2rem)]',
    title: 'K2 Pro',
    tooltips: [
      {
        content: 'Keyboard',
        svg: <KeyboardSvg />,
      },
      {
        content: 'Keychron',
        svg: <KeychronSvg />,
      },
    ],
    button: {
      text: 'More details',
      link: 'https://keychron.com.es/products/keychron-k2-pro-qmk-via-wireless-mechanical-keyboard?variant=43838915805350',
      tracking: 'keyboard',
    },
  },
  {
    cardStyles:
      'w-full bg-white md:w-[calc(50%-2rem)] lg:w-[calc(33%-2rem)] xl:w-[calc(25%-2rem)]',
    title: 'MX Master 3s',
    tooltips: [
      {
        content: 'Mouse',
        svg: <MouseSvg />,
      },
      {
        content: 'Logitech',
        svg: <LogitechSvg />,
      },
    ],
    button: {
      text: 'More details',
      link: 'https://www.logitech.com/es-es/products/mice/mx-master-3s.910-006559.html',
      tracking: 'mouse',
    },
  },
  {
    cardStyles:
      'w-full bg-white md:w-[calc(50%-2rem)] lg:w-[calc(33%-2rem)] xl:w-[calc(25%-2rem)]',
    title: 'S2421HS',
    tooltips: [
      {
        content: 'Screen',
        svg: <ScreenSvg />,
      },
      {
        content: 'Dell',
        svg: <DellSvg />,
      },
    ],
    button: {
      text: 'More details',
      link: 'https://www.dell.com/es-es/shop/monitor-dell-24-s2421hs/apd/210-axkq/monitores-y-accesorios',
      tracking: 'screen',
    },
  },
  {
    cardStyles:
      'w-full bg-white md:w-[calc(50%-2rem)] lg:w-[calc(33%-2rem)] xl:w-[calc(25%-2rem)]',
    title: 'EQ5/ED5',
    tooltips: [
      {
        content: 'Desk',
        svg: <DeskSvg />,
      },
      {
        content: 'FlexiSpot',
        svg: <FlexiSpotSvg />,
      },
    ],
    button: {
      text: 'More details',
      link: 'https://www.flexispot.es/escritorio-elevable-E5',
      tracking: 'desk',
    },
  },
  {
    cardStyles:
      'w-full bg-white md:w-[calc(50%-2rem)] lg:w-[calc(33%-2rem)] xl:w-[calc(25%-2rem)]',
    title: 'Markus',
    tooltips: [
      {
        content: 'Chair',
        svg: <ChairSvg />,
      },
      {
        content: 'Ikea',
        svg: <IkeaSvg />,
      },
    ],
    button: {
      text: 'More details',
      link: 'https://www.ikea.com/es/es/p/markus-silla-trabajo-vissle-gris-oscuro-70261150/',
      tracking: 'chair',
    },
  },
  {
    cardStyles:
      'w-full bg-white md:w-[calc(50%-2rem)] lg:w-[calc(33%-2rem)] xl:w-[calc(25%-2rem)]',
    title: 'Toputure TP-TM01W',
    tooltips: [
      {
        content: 'Treadmill',
        svg: <TreadmillSvg />,
      },
      {
        content: 'Amazon',
        svg: <AmazonSvg />,
      },
    ],
    button: {
      text: 'More details',
      link: 'https://www.amazon.es/gp/product/B0BVB4TZ1T/ref=ppx_yo_dt_b_asin_title_o08_s00',
      tracking: 'treadmill',
    },
  },
];
