import { useEffect } from 'react';

export default function Article (props) {
    useEffect(() => {
        // window.scrollTo(0, 0);
    }, []);

    return (
        <article className="mx-auto w-full max-w-4xl format format-sm sm:format-base lg:format-lg format-invert">
          <header className="mb-4 lg:mb-10 not-format">
            <address className="flex items-center mb-6 not-italic">
              <div className="inline-flex items-center mr-3 text-sm text-gray-200">
                <img
                  loading="lazy"
                  className="mr-4 w-16 h-16 rounded-full"
                  src={require('../../images/daniel-black-white-3.jpg')}
                  alt={props.author}
                />
                <div>
                  <p className="text-xl font-bold text-gray-300">
                    {props.author}
                  </p>
                  <p className="text-base text-gray-500 dark:text-gray-400">
                    {props.position}
                  </p>
                  <p className="text-base text-gray-500 dark:text-gray-400">
                    <time dateTime={props.date} title={props.dateFormatted}>
                      {props.dateFormatted}
                    </time>
                  </p>
                </div>
              </div>
            </address>
            <h1 className="mb-4 text-3xl font-extrabold leading-tight text-rose-600/90 lg:mb-6 lg:text-4x">
              {props.title}
            </h1>
            {props.mainImage && (
              <img
                loading="lazy"
                className="rounded-lg"
                src={`${props.mainImage.src}`}
                alt={`${props.mainImage.alt}`}
              />
            )}
          </header>
          {props.content}
        </article>
    )
}