import JobDetail from './JobDetail';
import JobInformation from './JobInformation';

export default function Job(props) {
    const positionId = props.position.id;
    const positionInformation = props.position.positionInformation;
    const positionDetail = props.position.positionDetail;
    return (
        <div key={`job-container-${positionId}`} className='lg:flex justify-between mb-14'>
            <JobInformation key={`job-information-${positionId}`} {...positionInformation}/>
            <JobDetail key={`job-detail-${positionId}`} {...positionDetail } positionId={positionId} />
        </div>
    )
}