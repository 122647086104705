export default function NestJSRetry(props) {
    return (
        <div
            className="text-gray-300 font-semibold text-[14px] sm:text-[16px] leading-8"
          >
            Well, I'm here to explain in a simple post how we can configure retries in an API call using the Http Module package of NestJS. <br />

            To learn more about the module, you can refer to the official NestJS documentation, but all we really need to know is that it is essentially a wrapper for Axios. <br />

            So knowing that, initial step would be to install the necessary dependencies: <br />

            <div className="mt-6 mb-6 bg-black p-6 rounded-md text-gray-300/80">
                <span className="pr-2.5 pl-3 text-gray-700">$</span>npm i --save @nestjs/axios axios
            </div>
            
            The next step would be to import our module wherever we want to use it. For instance, we could install it in our cars module: <br />

            <div className="mt-6 mb-6 bg-black p-6 rounded-md text-blue-200/90 text-[10px] sm:text-[16px]">
              {/* <span className="pl-3 text-rose-600/90">{`@`}</span><span>{`Module`}</span><span className="text-rose-600/90">{`(`}</span><span className="text-[#E1AB00]">{`{`}</span> <br />
              <span className="pl-10">{`controllers: `}</span><span className="text-[#E1AB00]">{`[`}</span><span className="text-[#84EA18]">{`CarsController`}</span><span className="text-[#E1AB00]">{`],`}</span> <br />
              <span className="pl-10">{`imports: `}</span><span className="text-[#E1AB00]">{`[`}</span><span className="text-[#84EA18]">{`PrismaModule, HttpModule`}</span><span className="text-[#E1AB00]">{`],`}</span> <br />
              <span className="pl-10">{`providers: `}</span><span className="text-[#E1AB00]">{`[`}</span><br />
              <span className="pl-16 text-[#E1AB00]">{`{`}</span> <br />
              <span className="pl-24">{`provide: `}</span><span className="text-[#E1AB00]">{`'ICarsRepository'`}</span><span>{`,`}</span> <br />
              <span className="pl-24">{`useClass: `}</span><span className="text-[#E1AB00]">{`CarsRepository`}</span><span>{`,`}</span> <br />
              <span className="pl-16 text-[#E1AB00]">{`}`}</span> <br />
              <span className="pl-16 text-[#84EA18]">{`CarsService`}</span><span>{`,`}</span> <br />
              <span className="pl-16 text-[#84EA18]">{`Logger`}</span><span>{`,`}</span> <br />
              <span className="pl-10 text-[#E1AB00]">{`],`}</span> <br />
              <span className="pl-10">{`exports: `}</span><span className="text-[#E1AB00]">{`[`}</span><span className="text-[#84EA18]">{`CarsService, CarsRepository`}</span><span className="text-[#E1AB00]">{`],`}</span> <br />
              <span className="pl-3 text-[#E1AB00]">{`}`}</span><span className="text-rose-600/90">{`)`}</span> <br />
              <span className="pl-3 text-rose-600/90">{`export class `}</span><span>{`CarsModule `}</span><span className=" text-[#E1AB00]">{`{}`}</span> <br /> */}
              <span className="pl-3 ">{`@Module({`}</span> <br />
              <span className="pl-10">{`controllers: [CarsController],`}</span><br />
              <span className="pl-10">{`imports: [PrismaModule, HttpModule],`}</span><br />
              <span className="pl-10">{`providers: [`}</span><br />
              <span className="pl-16 ">{`{`}</span> <br />
              <span className="pl-24">{`provide: 'ICarsRepository',`}</span><br />
              <span className="pl-24">{`useClass: CarsRepository,`}</span><br />
              <span className="pl-16 ">{`}`}</span> <br />
              <span className="pl-16 ">{`CarsService,`}</span><br />
              <span className="pl-16 ">{`Logger,`}</span><br />
              <span className="pl-10 ">{`],`}</span> <br />
              <span className="pl-10">{`exports: [CarsService, CarsRepository],`}</span><br />
              <span className="pl-3 ">{`}`}</span><br />
              <span className="pl-3 ">{`export class CarsModule {}`}</span><br />
            </div>

            Once we have it imported into our module, we'll be able to inject it as a dependency through the constructor in any class within our "cars" module, for example, inside the CarsService.

            <div className="mt-6 mb-6 bg-black p-6 rounded-md text-blue-200/90 text-[10px] sm:text-[16px]">
              {/* <span className="pl-3 text-rose-600/90">{`@`}</span><span>{`Injectable`}</span><span className="text-rose-600/90">{`()`}</span> <br />
              <span className="pl-3 text-rose-600/90">{`export class `}</span><span>{`CarService `}</span><span className="text-[#E1AB00]">{`{`}</span> <br />
              <span className="pl-10 text-blue-800">{`constructor`}</span><span className="text-rose-600/90">{` (private readonly `}</span><span>httpService: </span><span className="text-[#84EA18]">{`HttpService`}</span><span className="text-rose-600/90">{`)`}</span> <span className="text-[#E1AB00]">{`{}`}</span><br />
              <span className="pl-10 text-rose-600/90">{`public `}</span><span className="text-[#E1AB00]">{`findAll(): `}</span><span className="text-[#84EA18]">Observable</span><span className="text-[#E1AB00]">{`<`}</span><span>{`AxiosResponse`}</span> <span className="text-[#E1AB00]">{`{}`}</span><br />
              <span style={{paddingLeft: '70px'}}>{`return this.httpService.get('http://localhost:3000/cars');`}</span> <br />
              <span style={{paddingLeft: '40px'}}>{`}`}</span> <br />
              <span style={{paddingLeft: '10px'}}>{`}`}</span> <br /> */}

              <span className="pl-3 ">{`@Injectable()`}</span> <br />
              <span className="pl-3 ">{`export class CarService {`}</span> <br />
              <span className="pl-10 ">{`constructor(private readonly httpService: HttpService) {}`}</span> <br />
              <span className="pl-10 ">{`public findAll(): Observable<AxiosResponse<any>> {`}</span> <br />
              <span className="pl-16 ">{`return this.httpService.get('http://localhost:3000/cars');`}</span> <br />
              <span className="pl-10 ">{`}`}</span> <br />
              <span className="pl-3 ">{`}`}</span> <br />
            </div>

            As we can see, we've made a rather simple GET call and haven't configured anything in this case. <br />

            Therefore, we will update this same method so that, if we encounter an internal API error, we'll attempt to retry the request. <br />

            In this scenario, we'll retry whenever the response status is ≥ 500. To achieve this, I'll create my own error, which will indicate when I should retry the request based on the status code of the error.

            <div className="mt-6 mb-6 bg-black p-6 rounded-md text-blue-200/90 text-[10px] sm:text-[16px]">
              <span className="pl-3">{`class CarsApiError extends Error {`}</span> <br />
              <span className="pl-10">{`constructor(message, status) {`}</span> <br />
              <span className="pl-16">{`super(message);`}</span> <br />
              <span className="pl-16">{`this.status = status;`}</span> <br />
              <span className="pl-10">{`}`}</span> <br />
              <span className="pl-3">{`}`}</span> <br />
            </div>

            Now, all that's left is to configure our call. <br />

            First of all, it's important to know that the return value of the HttpService methods (GET, POST, etc.) is an observable, so we can leverage rxjs. <br />

            We will use pipe to chain these operators in a more readable and composable way. <br />

            Within this chain, we will use map to filter the values of the response. <br />

            <div className="mt-6 mb-6 bg-black p-6 rounded-md text-blue-200/90 text-[10px] sm:text-[16px]">
              <span className="pl-36 text-[#E1AB00] text-3xl">{`...`}</span> <br />
              <span className="">{``}</span> <br />
              <span className="pl-3">{`const response = this.httpService`}</span> <br />
              <span className="pl-10">{`.get('http://localhost:3000/cars')`}</span> <br />
              <span className="pl-10">{`.pipe(`}</span> <br />
              <span className="pl-16">{`map((response) => {`}</span> <br />
              <span className="pl-24">{`if (response.status === HttpStatus.OK) {`}</span> <br />
              <span className="pl-32">{`return response.data;`}</span> <br />
              <span className="pl-24">{`}`}</span> <br />
              <span className="">{``}</span> <br />
              <span className="pl-24">{`throw new CarsApiError(response.message, response.status);`}</span> <br />
              <span className="pl-16">{`})`}</span> <br />
              <span className="pl-16">{`catchError((error: Error) => {`}</span> <br />
              <span className="pl-24">{`this.logger.error(error);`}</span> <br />
              <span className="pl-24">{`throw error;`}</span> <br />
              <span className="pl-16">{`}),`}</span> <br />
              <span className="">{``}</span> <br />
              <span className="pl-36 text-[#E1AB00] text-3xl">{`...`}</span> <br />
            </div>

            As we can observe, if the API response is not OK (200), we will throw an instance of the previously created error with the status of the response. Similarly, we will have a catch block for any potential errors not handled by the API and to log any errors in our logs. <br />

            Now, it's important to note that there is a retry option, which allows us to configure the way retry attempts are made. <br />


            <div className="mt-6 mb-6 bg-black p-6 rounded-md text-blue-200/90 text-[10px] sm:text-[16px]">
              <span className="">{``}</span> <br />
              <span className="pl-3">{`const response = this.httpService`}</span> <br />
              <span className="pl-10">{`.get('http://localhost:3000/cars')`}</span> <br />
              <span className="pl-10">{`.pipe(`}</span> <br />
              <span className="pl-16">{`map((response) => {`}</span> <br />
              <span className="pl-24">{`if (response.status === HttpStatus.OK) {`}</span> <br />
              <span className="pl-32">{`return response.data;`}</span> <br />
              <span className="pl-24">{`}`}</span> <br />
              <span className="">{``}</span> <br />
              <span className="pl-24">{`throw new CarsApiError(response.message, response.status);`}</span> <br />
              <span className="pl-16">{`})`}</span> <br />
              <span className="pl-16">{`catchError((error: Error) => {`}</span> <br />
              <span className="pl-24">{`this.logger.error(error);`}</span> <br />
              <span className="pl-24">{`throw error;`}</span> <br />
              <span className="pl-16">{`}),`}</span> <br />
              <span className="pl-16">{`retry({`}</span> <br />
              <span className="pl-24">{`count: retryCount,`}</span> <br />
              <span className="pl-24">{`delay: (error: Error, retryCount: number) => {`}</span> <br />
              <span className="pl-32">{`if (error instanceof CarsApiError`}</span> <br />
              <span className="pl-36">{`&& error.status >= HttpStatus.INTERNAL_SERVER_ERROR) {`}</span> <br />
              <span className="pl-36">{`this.logger.warn(`}</span> <br />
              <span className="pl-40">{`\`Retrying GET Cars endpoint. Retry: \${retryCount}\`,`}</span> <br />
              <span className="pl-36">{`);`}</span> <br />
              <span className="">{``}</span> <br />
              <span className="pl-36">{`return timer(retryDelay);`}</span> <br />
              <span className="pl-32">{`}`}</span> <br />
              <span className="">{``}</span> <br />
              <span className="pl-32">{`throw error;`}</span> <br />
              <span className="pl-24">{`},`}</span> <br />
              <span className="pl-16">{`}),`}</span> <br />
              <span className="">{``}</span> <br />
              <span className="pl-36 text-[#E1AB00] text-3xl">{`...`}</span> <br />
            </div>


            The first parameter (count) allows us to specify the number of times we want to retry our request. <br />

            The second parameter (delay) enables us to pass a function where we receive the error as a function param and the current retry number. <br /> <br />

            In our case, as mentioned earlier, we only want to retry those errors thrown by us previously when the API responds with a status ≥ 500. Therefore, once we create our condition, if everything is met, we return a timer (a timer is used to generate values at defined time intervals) with a value we find appropriate. <br /> <br />

            In this scenario, both the values for retryCount and retryDelay are obtained before making our request, relying on the ConfigService of NestJS, which allows us to access those values previously configured in the application's environment. <br />

            <div className="mt-6 mb-6 bg-black p-6 rounded-md text-blue-200/90 text-[10px] sm:text-[16px]">
              <span className="pl-3">{`const retryCount = parseInt(this.config.get('HTTP_SERVICE_RETRY_COUNT'));`}</span> <br />
              <span className="pl-3">{`const retryDelay = parseInt(this.config.get('HTTP_SERVICE_RETRY_DELAY'));`}</span> <br />
            </div>

            If it doesn't meet our conditions, it means that the error is not one we want to retry, so we throw the error again, and the execution continues. <br /> <br />

            Now that we know we will get an Observable, to obtain the information in the form of a Promise, we can use lastValueFrom and get the last emitted value to work with. In this case, we need to wait for the value to construct our Entity that we will work with internally and that we must return. <br />

            <div className="mt-6 mb-6 bg-black p-6 rounded-md text-blue-200/90 text-[10px] sm:text-[16px]">
              <span className="">{`const carValues = await lastValueFrom(response);`}</span> <br />
              <span className="">{`return Car.toEntity(... carValues);`}</span> <br />
            </div>

            Finally, our complete code should look something like the following: <br />

            
            <div className="mt-6 mb-6 bg-black p-6 rounded-md text-blue-200/90 text-[10px] sm:text-[16px]">
              <span className="pl-3">{`@Injectable()`}</span> <br />
              <span className="pl-3">{`export class CarsService {`}</span> <br />
              <span className="pl-10">{`constructor(`}</span> <br />
              <span className="pl-16">{`private readonly httpService: HttpService,`}</span> <br />
              <span className="pl-16">{`private readonly config: ConfigService`}</span> <br />
              <span className="pl-16">{`private readonly logger: Logger,`}</span> <br />
              <span className="pl-10">{`) {}`}</span> <br />
              <span className="">{``}</span> <br />
              <span className="pl-10">{`public async findAll(): Promise<Car> {`}</span> <br />
              <span className="pl-16">{`const retryCount = parseInt(this.config.get('HTTP_SERVICE_RETRY_COUNT'));`}</span> <br />
              <span className="pl-16">{`const retryDelay = parseInt(this.config.get('HTTP_SERVICE_RETRY_DELAY'));`}</span> <br />
              <span className="">{``}</span> <br />
              <span className="pl-16">{`const response = this.httpService`}</span> <br />
              <span className="pl-24">{`.get('http://localhost:3000/cars')`}</span> <br />
              <span className="pl-24">{`.pipe(`}</span> <br />
              <span className="pl-32">{`map((response) => {`}</span> <br />
              <span className="pl-36">{`if (response.status === HttpStatus.OK) {`}</span> <br />
              <span className="pl-44">{`return response.data;`}</span> <br />
              <span className="pl-36">{`}`}</span> <br />
              <span className="">{``}</span> <br />
              <span className="pl-32">{`throw new CarsApiError(response.message, response.status);`}</span> <br />
              <span className="pl-24">{`})`}</span> <br />
              <span className="pl-24">{`catchError((error: Error) => {`}</span> <br />
              <span className="pl-32">{`this.logger.error(error);`}</span> <br />
              <span className="pl-32">{`throw error;`}</span> <br />
              <span className="pl-24">{`}),`}</span> <br />
              <span className="pl-24">{`retry({`}</span> <br />
              <span className="pl-32">{`count: retryCount,`}</span> <br />
              <span className="pl-32">{`delay: (error: Error, retryCount: number) => {`}</span> <br />
              <span className="pl-40">{`if (error instanceof CarsApiError`}</span> <br />
              <span className="pl-44">{`&& error.status >= HttpStatus.INTERNAL_SERVER_ERROR) {`}</span> <br />
              <span className="pl-44">{`this.logger.warn(`}</span> <br />
              <span className="pl-48">{`\`Retrying GET Cars endpoint. Retry: \${retryCount}\`,`}</span> <br />
              <span className="pl-44">{`);`}</span> <br />
              <span className="">{``}</span> <br />
              <span className="pl-44">{`return timer(retryDelay);`}</span> <br />
              <span className="pl-40">{`}`}</span> <br />
              <span className="">{``}</span> <br />
              <span className="pl-40">{`throw error;`}</span> <br />
              <span className="pl-32">{`},`}</span> <br />
              <span className="pl-24">{`}),`}</span> <br />
              <span className="pl-16">{`);`}</span> <br />
              <span className="">{``}</span> <br />
              <span className="pl-16">{`const carValues = await lastValueFrom(response);`}</span> <br />
              <span className="">{``}</span> <br />
              <span className="pl-16">{`return Car.toEntity(... carValues);`}</span> <br />
              <span className="pl-10">{`}`}</span> <br />
              <span className="pl-3">{`}`}</span> <br />
            </div>
            
            I hope you find this post helpful and that it helps you to understand how to configure retries in an API call using the Http Module package of NestJS.
          </div>
    )
}