import { useEffect } from 'react';
import BlogContainer from '../components/Blog/BlogContainer';
import Post from '../components/Blog/Post';
import { blogPosts } from '../helpers/blog';
import { PageView } from '../metrics/GA';

export default function Blog() {
  useEffect(() => {
    document.title = 'Dortega Blog';
    PageView();
  }, []);
  return (
    <div>
      <BlogContainer>
        {Object.values(blogPosts).map((value) => (
          <Post key={`post-container-${value.id}`} content={value} />
        ))}
      </BlogContainer>
    </div>
  );
}
