import StandingDesk from '../components/Blog/Content/StandingDesk';
import NestJSRetry from '../components/Blog/Content/NestJSRetry';

export const blogPosts = [
  {
    id: 2,
    category: 'WebDev',
    date: '2024-02-23',
    mainImage: {
      src: require('../images/blog/nestjs-logo.png'),
      alt: 'NestJS Logo',
    },
    dateFormatted: 'February 23, 2024',
    title: 'NestJS: HttpService retry configuration',
    slug: 'nestjs-httpservice-retry-configuration',
    author: 'Daniel Ortega',
    position: 'Software Engineer',
    preview: `Pretty simple and straightforward guide to configure the HttpService to retry requests in case of failure.`,
    content: <NestJSRetry />,
  },
  {
    id: 1,
    category: 'Lifestyle',
    date: '2024-01-12',
    mainImage: {
      src: require('../images/blog/standing_desk_setup.jpg'),
      alt: 'Standing Desk',
    },
    dateFormatted: 'January 12, 2024',
    title: 'Standing Desk. Worth it?',
    slug: 'standing-desk-worth-it',
    author: 'Daniel Ortega',
    position: 'Software Engineer',
    preview: `I bought it in late 2022 and I can't imagine going back to a regular desk. However, there are some cons and I'll let you know more in this post.`,
    content: <StandingDesk />,
  },
];
