import { useParams } from 'react-router-dom';
import { blogPosts } from '../helpers/blog';
import { useEffect } from 'react';
import Article from '../components/Blog/Article';
import { PageView } from '../metrics/GA';

export default function BlogDetail(props) {
  const { slug } = useParams();
  const data = blogPosts.find((post) => post.slug === slug);

  useEffect(() => {
    document.title = 'Dortega Blog: ' + data.title;
    PageView();
  }, [data.title]);

  if (data === undefined) {
    // TODO: Add 404 component/page
    return <></>;
  }

  return (
    <div className="pt-40 pb-8 bg-gray-900 antialiased">
      <div className="flex justify-between px-6 lg:px-0 xl:px-0 mx-auto max-w-screen-xl ">
        <Article {...data} />
      </div>
    </div>
  );
}
