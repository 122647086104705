
export default function ContainerTitle(props) {
    let classes = 'max-w-7xl px-6 mt-20 mx-auto scroll-mt-28 ';

    if (props.customClasses !== undefined) {
        classes += props.customClasses;
    }

    return (
        <div id={props.containerId} className={classes}>
            {props.title !== undefined && <h2 className="font-bold text-4xl text-gray-300 text-center mb-5">
                {props.title}
            </h2>}
            {props.description !== undefined && <p className='max-w-4xl mx-auto text-gray-300'>{props.description}</p>}
            {props.children}
        </div>
    )
}