import React from "react";
import {
  Navbar as NavBarMaterial,
  Collapse,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { HashLink as Link } from 'react-router-hash-link';
 
function NavList(props) {
  return (
    <ul className="mt-8 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6 text-gray-300 pl-6">
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-bold text-md"
      >
        <Link to="/#Profile" onClick={props.closeMenuHandler} className="flex items-center hover:text-rose-600/90 transition-colors">
          Profile
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-bold text-md"
      >
        <Link to="/#Experience" onClick={props.closeMenuHandler} className="flex items-center hover:text-rose-600/90 transition-colors">
          Experience
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-bold text-md"
      >
        <Link to="/#Uses" onClick={props.closeMenuHandler} className="flex items-center hover:text-rose-600/90 transition-colors">
          Uses
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-bold text-md"
      >
        <Link to="/blog" onClick={() => {props.closeMenuHandler(); }} className="flex items-center hover:text-rose-600/90 transition-colors">
          Blog
        </Link>
      </Typography>
    </ul>
  );
}
 
export function Navbar() {
  const [openNav, setOpenNav] = React.useState(false);
  const [scrolled, setScrolled] = React.useState(false);
 
  const handleWindowResize = () =>
    window.innerWidth >= 960 && setOpenNav(false);
 
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
 
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  React.useEffect(() => {
    const onScroll = () => {
      if (window.scrollY >= 65) {
        setScrolled(true);
      } else if (window.scrollY <= 64) {
        setScrolled(false);
      }
    };
    
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    }
  }, []);

  let navbarClass = 'mx-auto py-6 rounded-none dark:bg-gray-900 border-x-0 border-t-0 border-b-8 border-rose-600/90 fixed top-0 z-10';

  if (scrolled) {
    navbarClass += ' py-3';
  }

  const onCloseMenuHandler = () => {
    setOpenNav(false);
  }
 
  return (
    <NavBarMaterial className={navbarClass}>
      <div className="flex items-center mx-auto max-w-7xl px-6 justify-between">
        <Link to="/#top" onClick={onCloseMenuHandler} className="">
          <img src={require('../../images/logo.png')} alt='Dortega logo' className={scrolled ? 'h-8 w-40' : 'h-10 w-60'} loading="lazy" />
        </Link>
        <div className="hidden lg:block">
          <NavList />
        </div>
        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden [&>span]:relative"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
          aria-label={`${openNav ? 'Close' : 'Open'} navigation menu`}
        >
          {openNav ? (
            <XMarkIcon className="h-6 w-6 text-rose-600/90" strokeWidth={2}/>
          ) : (
            <Bars3Icon className="h-6 w-6 text-rose-600/90" strokeWidth={2} />
          )}
        </IconButton>
      </div>
      <Collapse open={openNav}>
        <NavList closeMenuHandler={onCloseMenuHandler}/>
      </Collapse>
    </NavBarMaterial>
  );
}